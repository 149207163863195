import axios from 'axios'

const api = axios.create({
    baseURL: 'https://qc.220191.xyz/api',
})

export const check = async (region, realmSlug, characterName) => {
    const { data } = await api.get('/check', {
        params: { region, realmSlug, characterName },
    })

    return data
}
