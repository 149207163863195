import { useState } from 'react'
import { check } from './services/api'
import { classes } from './utils/classes'
import { normalize } from './utils/text'

const App = () => {
    const [region, setRegion] = useState('')
    const [realmSlug, setRealmSlug] = useState('')
    const [characterName, setCharacterName] = useState('')
    const [results, setResults] = useState([
        // { mountName: 'Armored Razzashi Raptor', collected: false },
        // { mountName: "Cartel Master's Gearglider", collected: false },
        // { mountName: "Rivendare's Deathcharger", collected: false },
        // { mountName: 'Marrowfang', collected: true },
        // { mountName: 'Mechagon Peacekeeper', collected: false },
        // { mountName: 'Midnight', collected: true },
        // { mountName: 'Tomb Stalker', collected: false },
        // { mountName: 'Blue Proto-Drake', collected: false },
        // { mountName: 'Drake of the North Wind', collected: false },
        // { mountName: 'Infinite Timereaver', collected: true },
        // { mountName: 'Vitreous Stone Drake', collected: false },
        // { mountName: 'Raven Lord', collected: false },
        // { mountName: 'Sharkbait', collected: false },
        // { mountName: 'Swift White Hawkstrider', collected: true },
        // { mountName: 'Swift Zulian Panther', collected: false },
        // { mountName: 'Underrot Crawg', collected: false },
    ])
    const [loading, setLoading] = useState(false)

    const handleCheck = async e => {
        e.preventDefault()

        setLoading(true)

        check(normalize(region), normalize(realmSlug), normalize(characterName))
            .then(response => setResults(response.mountStatuses))
            .catch(err => console.log(err))
            .finally(() => setLoading(false))
    }

    return (
        <div className="min-w-full min-h-screen flex flex-col justify-center align-middle bg-gray-700">
            <div className="max-w-full w-4/12 m-auto bg-gray-800 p-4 rounded-md text-gray-400">
                <h1 className="text-3xl font-bold">Quantum Courser Checker</h1>
                <div className="flex flex-row justify-center align-middle my-8">
                    <div className="flex flex-col justify-center align-middle">Region:</div>
                    <input
                        className="w-12 bg-gray-600 rounded-md m-4 p-1 outline-0 text-center"
                        type="text"
                        value={region}
                        onChange={e => setRegion(e.target.value)}
                    />
                    <div className="flex flex-col justify-center align-middle">Realm:</div>
                    <input
                        className="w-32 bg-gray-600 rounded-md m-4 p-1 outline-0 text-center"
                        type="text"
                        value={realmSlug}
                        onChange={e => setRealmSlug(e.target.value)}
                    />
                    <div className="flex flex-col justify-center align-middle">Character name:</div>
                    <input
                        className="w-32 bg-gray-600 rounded-md m-4 p-1 outline-0 text-center"
                        type="text"
                        value={characterName}
                        onChange={e => setCharacterName(e.target.value)}
                    />
                    <button className="w-20 bg-gray-700 rounded-md m-4 p-1" disabled={loading} onClick={handleCheck}>
                        {loading ? 'Loading...' : 'Fetch!'}
                    </button>
                </div>

                <div className="grid grid-cols-2 gap-2">
                    {results.map(result => (
                        <div
                            className={classes(
                                'bg-gray-700 rounded-md p-1',
                                result.collected ? 'bg-green-900' : 'bg-red-900'
                            )}
                        >
                            {result.collected ? '✓' : 'ｘ'} {result.mountName}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default App
